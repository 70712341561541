import React, { useState, useEffect } from "react";
import styled from "styled-components";

const AdToggleContainer = styled.div`
  ${(props) => props.theme.Dice?.AdToggleContainer};
`;

const SecondAdToggleContainer = styled(AdToggleContainer)`
  margin-bottom: 3%;
`;

const LeftAdContent = styled.div`
  ${(props) => props.theme.Dice?.LeftAdContent};
`;

const RightAdContent = styled.div`
  ${(props) => props.theme.Dice?.RightAdContent};
`;

const ContentContainer = styled.div`
  ${(props) => props.theme.Dice?.ContentContainer};
`;

const ContentWrapper = styled.div`
  ${(props) => props.theme.Dice?.ContentWrapper};
`;

const ImgAd = styled.img`
  ${(props) => props.theme.Dice?.ImgAd};
`;

const ImgLogo = styled.img`
  ${(props) => props.theme.Dice?.ImgLogo};
`;

const AdToggle = () => {
  const img_lady = "/images/img_lady.png";
  const img_logo = "/images/client_logo.png";
  const img_spe = "/images/img_spe.png";
  const img_protipz = "/images/img_protipz.png";

  const divs = [
    <AdToggleContainer key="1">
      <LeftAdContent>
        <ImgLogo src={img_logo} alt="imgLogo" />
      </LeftAdContent>
      <RightAdContent>
        <ImgAd src={img_lady} alt="imgLady" />
      </RightAdContent>
    </AdToggleContainer>,
    <SecondAdToggleContainer key="2">
      <RightAdContent isSmallSize={true}>
        <ImgAd src={img_spe} alt="imgSPE" isSmallSize={true} />
      </RightAdContent>
    </SecondAdToggleContainer>,
    /*<SecondAdToggleContainer key="3">
      <RightAdContent isSmallSize={true}>
        <ImgAd src={img_protipz} alt="imgProtipz" isSmallSize={true} />
      </RightAdContent>
    </SecondAdToggleContainer>,*/
  ];
  const [currentDivIndex, setCurrentDivIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDivIndex((prevIndex) => (prevIndex + 1) % divs.length);
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [divs.length]);

  return <>{divs[currentDivIndex]}</>;
};

export default AdToggle;
